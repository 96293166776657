<template>
	<div class="hero-head mb-6">
		<div style="width: 30%; height: 4px; background-color:  hsl(171, 100%, 96%);position: absolute;"></div>
		<nav class="navbar is-fixed-top myBacks">
			<div class="container">
				<div class="navbar-brand">
					<router-link class="navbar-item sayMyName gli" @mouseover="isHover = true" 
					@mouseleave="isHover = false"  to="/">
						alejandro_h_rosas
					</router-link>
					<div role="button" v-bind:class="{ 'is-active': isActive }" v-on:click="isActive = !isActive" class="navbar-burger" data-target="navmenu" aria-label="menu"  aria-expanded="false">
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</div>
				</div>
				<div id="navmenu" class="navbar-menu tatan" v-bind:class="{ 'is-active': isActive }">
					<div class="navbar-end">
						
							<router-link class="navbar-item" to="/intro">
								<VueTextGlitch text="intro" v-bind:steps="1" >
								</VueTextGlitch>
							</router-link>
							<router-link class="navbar-item" to="/bio">
								<VueTextGlitch text="bio"  >
								</VueTextGlitch>
							</router-link>
							<router-link class="navbar-item" to="/piezas" >
								<VueTextGlitch text="piezas"></VueTextGlitch>
							</router-link>
							<router-link class="navbar-item" to="/nullbeat">
								<VueTextGlitch text="nu||beat" ></VueTextGlitch>
							</router-link>
							<router-link class="navbar-item" to="/contacto">
								<VueTextGlitch text="contacto" ></VueTextGlitch>
							</router-link>
						
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
import VueTextGlitch from 'vue-text-glitch'

export default {
	name: 'NullMenu',
	components: {
		VueTextGlitch
	},
	data () {
		return {
			isActive:false,
			isHover:false
		}
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
	@mixin glitchCopy { 
		content: "alejandro_h_rosas";
		position: absolute;
		top: 11px;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.25;
	}
	.sayMyName{
		font-family: 'Web Serveroff', sans-serif;
		font-weight: lighter;
		font-size: 20pt;
		letter-spacing: .3rem;

		color: #575A5E;
		opacity: 0.80;
		font-style:  normal;
		font-variant: normal; 

		line-height: 30px;

	}
	.tatan
	{
		font-family: 'Josefin Sans', sans-serif;
	}
	.sayMyName:hover{
		color: #575A5E;
	}
	.gli:hover{
		animation: glitch-skew 1s infinite linear alternate-reverse;
	
		&::after{
			// Duplicates our text with the mixin.
			@include glitchCopy;
			// Scoots text slightly to the left for the color offset.
			left: 12px;
			// Creates the color 'shadow' that happens on the glitch.
			text-shadow: 2px 0 #EBFFFC;
			/* Creates an initial clip for our glitch. This works in
			a typical top,right,bottom,left fashion and creates a mask
			to only show a certain part of the glitch at a time. */
			//clip: rect(44px, 450px, 56px, 0);
			/* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
			with an alternating animation to keep things fresh. */
			animation: glitch-anim 1s infinite linear normal;
		}
	}
	@keyframes glitch-anim {
		$steps: 10;
		@for $i from 0 through $steps {
			#{percentage($i*(1/$steps))} {
				transform: translateX($i+px);
			}
		}
	}
	.myBacks{
		background-color:rgba(255,255,255,0.83) !important;
	}
</style>