<template>
	<div class="container">
		<div class="columns is-centered is-vcentered">
			<div class="column is-8 ">
				<p class="title is-family-code has-text-weight-light colorts">
					///intro///
				</p>
				<p class="has-text-justified">
					Mi labor artística, parte frecuentemente del interés por el fenómeno sonoro en sus distintas manifestaciones. El sonido como materia; el sonido como fundamento para el accionar artístico; el sonido como un elemento que incide en distintos contextos sociales. Estos, son puntos de partida que fluyen y cimientan el proceso de producción. 
					Conceptos como lo efímero, lo ambiguo, lo ubicuo, la disonancia; inspiran la conceptualización del proceso, además de sus materiales, formas, sonidos e interacciones. De esta manera, mi trabajo busca generar un diálogo, que parte desde una reflexión introspectiva hacia construir una conversación alrededor del contexto donde la obra de arte sucede. 
					El cómputo, lo digital, lo análogo, la programación, la electrónica, son los medios, en los cuales se materializan dichos procesos; para finalmente, consolidarse en la producción de piezas sonoras, música, acciones, instalaciones interactivas, intervenciones en espacio público y piezas audiovisuales.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'NullStatement',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
.colorts
{
	color:hsl(171, 100%, 36%) !important;;
}
</style>