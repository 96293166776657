<template>
		
	<div class="container">
		<div class="columns is-centered is-vcentered mt-5">
			<div class="column mb-6 has-text-centered" >
				<span class="icon is-clickable iconSized">
					<a href="mailto:alejandro.hernandez.rosas@gmail.com?subject=Hola!">
						<i class="fas fa-envelope fa-4x"></i>
						<p class="is-size-7">alejandro.hernandez.rosas@gmail.com</p>
					</a>
				</span>
			</div>
			<div class="column mb-6 has-text-centered">
				<span class="icon is-clickable iconSized">
					<a href="https://soundcloud.com/alexhrosas" target="_blank">
						<i class="fab fa-soundcloud fa-4x"></i>
						<p class="is-size-7">soundcloud.com/alexhrosas</p>
					</a>
				</span>
			</div>
			<div class="column mb-6 has-text-centered">
				<span class="icon is-clickable iconSized">
					<a href="https://www.instagram.com/alexh.rosas/" target="_blank">
						<i class="fab fa-instagram fa-4x"></i>
						<p class="is-size-7">instagram.com/alexh.rosas</p>
					</a>
				</span>
			</div>
			<div class="column mb-6 has-text-centered">
				<span  class="icon is-clickable iconSized">
					<a href="https://github.com/alexHrosas" target="_blank">
						<i class="fab fa-github fa-4x"></i>
						<p class="is-size-7">github.com/alexHrosas</p>
					</a>
				</span>
			</div>
			<div class="column mb-6 has-text-centered">
				<span  class="icon is-clickable iconSized">
					<a href="https://www.facebook.com/alexHdRosas/" target="_blank">
						<i class="fab fa-facebook-square fa-4x"></i>
						<p class="is-size-7">facebook.com/alexHdRosas</p>
					</a>
				</span>
			</div>
		</div>
	</div>
		
</template>
<script>
export default {

  name: 'NullContact',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
	.iconSized a
	{
		color:#4a4a4a !important;
	}
	.iconSized a:hover
	{
		color:hsl(171, 100%, 36%) !important;;
	}
	
</style>