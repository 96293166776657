<template>
	<div class="container">
		<div class="columns is-centered is-vcentered">
			<div class="column is-8 ">
				<p class="title is-family-code has-text-weight-light colorts">
					///bio///
				</p>
				<p class="has-text-justified">
					Alejandro H. Rosas. (Estado de México, México 1987) artista sonoro, músico e ingeniero. Estudió Ingeniería en Sistemas Computacionales en la Escuela Superior de Cómputo (IPN) especializándose en sistemas interactivos. Ha desarrollado su formación artística en diversos espacios del Centro Nacional de las Artes, como el Centro Multimedia y el Centro de Capacitación Cinematográfica.
					Su trabajo creativo parte de un interés del fenómeno sonoro. A través la electrónica, el código de programación y demás herramientas digitales, construye procesos que se desenvuelven y dialogan en distintos medios. 
					Su obra se ha presentado en diversos espacios como Laboratorio de Arte Alameda, Centro Multimedia, Facultad de Música, Casa de Lago Juan José Arreola, Biblioteca Vasconcelos Y Ex Teresa Arte Actual. 
					Ha participado en festivales como Simposio Internacional de Música y Código /*vivo*/ (2014), Bienal de Performance Horas Perdidas (2016), Festival de Cine y Arte Digital Tlanchana (2017), International Conference on Live Coding (2017) y Network Music Festival (2020). 
					Beneficiario del Programa de Estímulo a la Creación y Desarrollo Artístico (PECDA) Estado de México (2017-2018)  Becario del Festival Internacional Visiones Sonoras en tres ocasiones (2013-2014-2015). Selección Oficial del 7° Festival Internacional de Arte Sonoro SONOM (2015). 

				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'NullBio',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
.colorts
{
	color:hsl(171, 100%, 36%) !important;;
}
</style>