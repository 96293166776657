<template>
	<div>
		<div class="columns">
			<div class="column is-2 is-1-desktop ">
				<div class="subtitle is-size-6 has-text-grey-light is-italic has-text-right has-text-weight-light is-family-monospace mb-5">
					tags
				</div>
				<div class="columns is-multiline is-mobile has-text-right">
					<div class="column is-2-mobile is-12-desktop" >
						<span class="tag  is-clickable tagclic" :class="{clicked: selectedTag == 0  }" v-on:click="selecting(0)" >
							todas
						</span>
					</div>
					<div  class="column is-3-mobile is-12-desktop" v-for="(item, index) in $options.jsonData.tags" :key="index" >
						<span class="tag is-clickable tagclic" :class="{clicked: selectedTag == index+1 }"  v-on:click="selecting(index+1)"  >
							{{item}}
						</span>
					</div>	
				</div>
				
				
				
			</div>
			<div class="column is-10 is-11-desktop">

				<div class="columns">
					<div class="column is-one-third">
						<opus v-for="w in filteredData1" :key="w.id"  v-bind:work="w" 
							v-bind:clickEvent="showModal" ></opus>	
					</div>
					<div class="column is-one-third">
						<opus v-for="w in filteredData2" :key="w.id"  v-bind:work="w" 
							v-bind:clickEvent="showModal" ></opus>	
					</div>
					<div class="column is-one-third">
						<opus v-for="w in filteredData3" :key="w.id"  v-bind:work="w"  
						v-bind:clickEvent="showModal"></opus>	
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import JSON_DATA from '@/json/works.json'
import Opus from '@/components/Opus.vue'
import WinExe from '@/components/WinExe.vue'

export default {
	name: 'NullWorks',
	components:{
		Opus
	},
	jsonData: JSON_DATA,
	data () {
		return {
			selectedTag : 0,
			filteredData1:[],
			filteredData2:[],
			filteredData3:[]
		}
	},
	created(){
		this.queryWorks();
	},
	methods:{
		showModal:function(work){
			this.$modal.show(
				WinExe,
				{ work: work},
				{ 
					name:'WinExe',
					draggable: false, 
					adaptive: true,
					width : "90%",
					height : "85%"
				}
			);
		},
		queryWorks:function(){
			if(this.selectedTag == 0)
			{
				let from = 0;
				let to = Math.round(this.$options.jsonData.works.length/3);
				this.filteredData1 =  this.$options.jsonData.works.slice(from,to);
				from  = to;
				to = to*2;
				this.filteredData2 =  this.$options.jsonData.works.slice(from,to);
				from  = to;
				this.filteredData3 =  this.$options.jsonData.works.slice(from);
			}else{
				let selTag = this.$options.jsonData.tags[this.selectedTag-1];
				let filter =  this.$options.jsonData.works.filter( work => work.tags.includes(selTag) );
				let from = 0;
				let to =Math.round(filter.length/3);

				if(filter.length<=0)
					return;

				if(filter.length<=1){
					this.filteredData1 =  filter.slice(0);
					this.filteredData2 =  [];
					this.filteredData3 =  [];
				}else{
					this.filteredData1 =  filter.slice(from,to);
					from  = to;
					to = to*2;
					this.filteredData2 =  filter.slice(from,to);
					from  = to;
					this.filteredData3 =  filter.slice(from);
				}
			}
		},
		selecting:function(value){
			this.selectedTag = value;
			this.queryWorks();
		}

	}
}
</script>

<style lang="css" scoped>
	.tagclic:hover
	{
		background-color: hsl(171, 100%, 96%);
	}
	.clicked
	{
		background-color: hsl(171, 100%, 96%);	
	}
	.tags
	{
		color: #cccccc;
	}
</style>