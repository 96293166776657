<template>
	<div class="msf">
		<div>
			<div class="sss pt-2 pr-2">
				<span @click="$emit('close')" class="icon is-clickable">
					<i class="fas fa-times"></i>
				</span>
			</div>
			
		</div>
		<div class="block pl-6 pr-6 pb-5">
			<article class="">
				<h4 class='subtitle is-family-monospace has-text-weight-semibold'>{{this.work.titulo}}</h4>
				<div class="is-italic is-family-monospace is-size-6 has-text-weight-light mb-2">{{this.work.fecha}}</div>
				<div class="is-family-sans-serif is-size-6 has-text-justified" v-html="work.texto"></div>
			</article>
		</div>
		<div class="block pl-6 pr-6 mb-3">
			<div v-for="(item, index) in this.work.inserts" :key="index" >
				<div v-html="item" class="mb-5"></div>
			</div>
		</div>
		<div class="block pl-5 pb-5">
			<div v-for="(item, index) in this.work.imgSrc" :key="index" 
				class="card is-pulled-left mys mr-2 mb-3">
				<div class="card-image">
					<figure class="image" >
						<img v-bind:src="getImg(item)" />
					</figure>
				</div>
			</div>
		</div>	
	</div>
</template>
<script>
export default {
	name: 'WinExe',
	props: {
		work : { type : Object, required: false}
	},
	data () {
		return {
			
		}
	},
	methods:{
		getImg:function(str){
			return process.env.BASE_URL + 'portafolio/' + str;
		}
	}
}
</script>
<style lang="css" scoped>
	.mys
	{
		width: 30%;
	}
	.msf
	{
		overflow: auto;
		height: 100%;
	}
	.sss
	{
		text-align: right;
	}
</style>