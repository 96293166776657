<template>
  <section id="app" class="hero is-fullheight is-bold">
      <null-menu></null-menu>
    <div class="hero-body" >
      <router-view/>  
    </div>
  </section>    
</template>

<script type="text/javascript">
  import NullMenu from '@/views/layout/NullMenu.vue'

  export default {

  components: {
    NullMenu
  },
  name: 'app',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}

</script>
<style lang="scss">

@font-face {
  font-family: "Web Serveroff";
  src: local("Web Serveroff"),   url("./assets/fonts/Web_Serveroff.ttf") format("truetype");
}

</style>
