import Vue from 'vue'
import VueRouter from 'vue-router'
import NullInit from '../views/NullInit.vue'
import NullStatement from '../views/NullStatement.vue'
import NullBio from '../views/NullBio.vue'
import NullWorks from '../views/NullWorks.vue'
import NullContact from '../views/NullContact.vue'
import NullBeat from '../views/NullBeat.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Init',
		component: NullInit
	},
	{
		path: '/intro',
		name: 'Statement',
		component: NullStatement
	},
	{
		path: '/bio',
		name: 'Bio',
		component: NullBio
	},
	{
		path: '/piezas',
		name: 'piezas',
		component: NullWorks
	},
	{
		path: '/nullbeat',
		name: 'Beat',
		component: NullBeat
	},
	{
		path: '/contacto',
		name: 'Contacto',
		component: NullContact
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
