<template>
	<div class="divout">
		<div class="" id="p5Canvas"></div>
	</div>
</template>

<script>


export default {
	name: 'NullInit',

	data () {
		return {

		}
	},
	mounted() {   
		const script = function (p5) {
									
			// NOTE: Set up is here   
			p5.setup = function() {   
				let winH;
				let winW;

				winH = (p5.windowHeight-p5.windowHeight*0.01)/10;
				winH = p5.round(winH)*10;
				winW = (p5.windowWidth-p5.windowWidth*0.01)/10;
				winW = p5.round(winW)*10+10;   
				var canvas = p5.createCanvas(winW, winH);      
				canvas.parent('p5Canvas');
				p5.mouseX = p5.width * 0.4
				
								
				
			}       // NOTE: Draw is here
			p5.draw = function() {      
				p5.background(250,50);
				p5.blendMode(p5.SUBTRACT);
				p5.osc2();
				p5.osc3();
				
			}
			p5.osc2 = function(){
				let points = 500;
				p5.stroke(0, 184, 156,80);
				p5.strokeWeight(1);
				p5.noFill();
				p5.beginShape();
				p5.curveVertex(0,p5.height/2-50);
				for(let i = 0; i < points; i++){
					let x = p5.map(i,0,points-1,0,p5.width);
					let osc = p5.cos((p5.frameCount*-0.02) + i * p5.map(p5.mouseX,0,p5.width,0.0,0.01));
					let  y = p5.height / 2 + osc * p5.abs(p5.mouseY - p5.height/2)*0.5;
					p5.curveVertex(x,y);
				}
				p5.curveVertex(p5.width,p5.height/2+50);
				p5.endShape();
			}
			p5.osc3 = function(){
				let points = 20;
				p5.noStroke();
				p5.fill(236, 239, 241,p5.map(p5.cos(p5.frameCount*0.01),0,1,0,100));
				p5.curveVertex(0,p5.height/2-50);
				p5.rect(0,p5.height/2,10,10);
				for(let i = 0; i < points; i++){
					let x = p5.map(i,0,points-1,0,p5.width);
					let osc = p5.sin((p5.frameCount*0.05) + i * p5.map(p5.mouseX,0,p5.width,0,5));
					let  y = p5.height / 2 + osc * p5.abs(p5.mouseY - p5.height/2);
					p5.rect(x,y,x+1,y+7);
				}
			}
		}    // NOTE: Use p5 as an instance mode
		const P5 = require('p5');
		new P5(script)
	}
}
</script>

<style lang="css" scoped>
.divout 
{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
}	
</style>