<template>
	<div class="card mt-4 mb-5 is-shadowless" >
		<div class="card-image">
			<a href="#" v-on:click="onClick">
				<figure class="image is-clickable imgclic" >
					<img v-bind:src="firstImg" />
				</figure>
			</a>
		</div>
		<div class="card-content ">
			<a href="#" v-on:click="onClick">
				<p class="is-size-6 is-clickable is-family-monospace has-text-weight-semibold tagclic" >
					<slot name="titulo">{{work.titulo}}</slot>
				</p>
			</a>
			<p class="is-size-7 is-italic mt-3 has-text-right">
				{{work.fecha}}
			</p>
			<div class="ml-5 has-text-right">
				<span v-for="t in work.tags" :key="t" class="card-footer-item tag mt-3 ml-2">
					{{t}}
				</span>
			</div>
		</div>
		
	</div>
</template>

<script>

export default {
	name: 'Opus',
	props: {
		work: { type: Object, required: true },
		clickEvent: { type: Function, required: true }
	} ,
	data () {
		return {
			firstImg: process.env.BASE_URL + 'portafolio/' + this.work.img
		}
	},
	methods:{
		onClick:function(){
			this.clickEvent(this.work);
		}
	}
}
</script>

<style lang="css" scoped>
	.tagclic:hover
	{
		color:hsl(171, 100%, 36%);
		opacity: 0.85;
		text-decoration: underline;
	}
	.imgclic:hover
	{
		opacity: 0.92;
	}
	a
	{
		color:hsl(171, 100%, 36%);
	}
</style>
